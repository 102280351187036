<template>
  <Skeleton v-if="isLoading" class="py-6 mb-6" />
  <div v-else class="w-100 h-100">
    <ErrorComponent v-if="validateForm.length > 0 && showError" class="error-wrapper" title="Mohon lengkapi form" />
    <div class="w-100 select-area-wrapper">
      <div class="columns">
        <div class="column is-3 border-right">
          <span class="averta-bold is-size-5">Pilih Area</span>
          <div class="area-wrapper mt-4">

            <!-- sliceArea1 -->
            <div v-if="sliceArea1.length > 0" class="mb-3 slice-area-wrapper">
              <b-button v-for="(item, index) in sliceArea1" :key="item.area_id" class="mr-3 mb-5 mb-sm-0 btn-tabs"
                :class="{ 'is-active': area === item.area_id }" @click="changeIsActive(item, index)">
                <div class="white-space-normal is-flex is-flex-direction-column is-position-relative">
                  <p class="averta-bold white-space-md-nowrap">{{ item.area_name }}</p>

                  <div v-if="handleIsFillForm(index) && showError" class="alert-icon-wrapper">
                    <AlertIcon />
                  </div>
                </div>
              </b-button>
            </div>
            <!-- sliceArea1 -->

            <!-- sliceArea2 -->
            <div v-if="sliceArea2.length > 0" class="mb-3 slice-area-wrapper">
              <b-button v-for="(item, index) in sliceArea2" :key="item.area_id" class="mr-3 mb-5 mb-sm-0 btn-tabs"
                :class="{ 'is-active': area === item.area_id }" @click="changeIsActive(item, index)">
                <div class="white-space-normal is-flex is-flex-direction-column is-position-relative">
                  <p class="averta-bold white-space-md-nowrap">{{ item.area_name }}</p>

                  <div v-if="handleIsFillForm(index) && showError" class="alert-icon-wrapper">
                    <AlertIcon />
                  </div>
                </div>
              </b-button>
            </div>
            <!-- sliceArea2 -->

            <!-- sliceArea3 -->
            <div v-if="sliceArea3.length > 0" class="mb-3 slice-area-wrapper">
              <b-button v-for="(item, index) in sliceArea3" :key="item.area_id" class="mr-3 mb-5 mb-sm-0 btn-tabs"
                :class="{ 'is-active': area === item.area_id }" @click="changeIsActive(item, index)">
                <div class="white-space-normal is-flex is-flex-direction-column is-position-relative">
                  <p class="averta-bold white-space-md-nowrap">{{ item.area_name }}</p>

                  <div v-if="handleIsFillForm(index) && showError" class="alert-icon-wrapper">
                    <AlertIcon />
                  </div>
                </div>
              </b-button>
            </div>
            <!-- sliceArea3 -->

          </div>
        </div>
        <div class="column is-9">
          <TabComponentThree
          :dataAreas="areas"
          :dataComponents="sliceComponents"
          :indexArea.sync="indexArea"
          :selectedAreaId.sync="area"
          :selectedComponentId.sync="selectedComponentId"
          @handleChangeStep="handleChangeStepThree($event)"
          @handleValidateForm="handleValidateForm($event)" :showError="showError"
          @handleIsChange="handleIsChange($event)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Skeleton from '@/components/skeleton/Skeleton'
import TabComponentThree from './TabComponentThree'
import ErrorComponent from '@/components/ErrorComponent.vue'
import { AlertIcon } from '@/components/icons'

export default {
  name: 'LengkapiMaterial',
  props: ['showError', 'idSurveyUpdateItemPekerjaan'],
  components: {
    Skeleton,
    TabComponentThree,
    ErrorComponent,
    AlertIcon
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      isLoading: true,
      area: null,
      areas: null,
      indexArea: null,
      validateForm: [],

      selectedComponentId: null,
      sliceArea1: [],
      sliceArea2: [],
      sliceArea3: [],
      sliceComponents: {
        sliceComponent1: [],
        sliceComponent2: [],
        sliceComponent3: []
      }
    }
  },
  methods: {
    handleChangeStepThree (payload) {
      this.$store.commit('project/setDataLengkapiMaterial', this.areas)
    },
    changeIsActive (props, index) {
      this.indexArea = index
      this.area = props.area_id
      this.selectedComponentId = props.components[0].component_id
      this.sliceAreaOrComponent('component', props.components)
    },
    sliceAreaOrComponent (type, array) {
      const result = []
      if (array.length > 13 && array.length < 19) {
        // Bagi menjadi dua bagian
        const middleIndex = Math.ceil(array.length / 2)
        result.push(array.slice(0, middleIndex))
        result.push(array.slice(middleIndex))
        result.sort((a, b) => b.length - a.length)
        if (type === 'area') {
          this.sliceArea1 = result[0]
          this.sliceArea2 = result[1]
          this.sliceArea3 = []
        } else if (type === 'component') {
          this.sliceComponents = {
            sliceComponent1: result[0],
            sliceComponent2: result[1],
            sliceComponent3: []
          }
        }
      } else if (array.length >= 19) {
        // Bagi menjadi tiga bagian
        const firstSplitIndex = Math.ceil(array.length / 3)
        const secondSplitIndex = 2 * firstSplitIndex
        result.push(array.slice(0, firstSplitIndex))
        result.push(array.slice(firstSplitIndex, secondSplitIndex))
        result.push(array.slice(secondSplitIndex))
        result.sort((a, b) => b.length - a.length)
        if (type === 'area') {
          this.sliceArea1 = result[0]
          this.sliceArea2 = result[1]
          this.sliceArea3 = result[2]
        } else if (type === 'component') {
          this.sliceComponents = {
            sliceComponent1: result[0],
            sliceComponent2: result[1],
            sliceComponent3: result[2]
          }
        }
      } else {
        // Jika kurang dari atau sama dengan 13, tidak perlu membagi
        result.push(array)
        result.sort((a, b) => b.length - a.length)
        if (type === 'area') {
          this.sliceArea1 = result[0]
          this.sliceArea2 = []
          this.sliceArea3 = []
        } else if (type === 'component') {
          this.sliceComponents = {
            sliceComponent1: result[0],
            sliceComponent2: [],
            sliceComponent3: []
          }
        }
      }
    },
    getDataLengkapiMaterial () {
      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId ? this.$route.params.surveyId : this.idSurveyUpdateItemPekerjaan
      }
      this.$store
        .dispatch('projectV2/getDataLengkapiMaterial', payload)
        .then(response => {
          const res = response.data.data
          this.indexArea = 0
          this.area = res[0].area_id
          this.areas = res
          this.selectedComponentId = this.areas[0].components[0].component_id

          this.sliceAreaOrComponent('area', this.areas)
          this.sliceAreaOrComponent('component', this.areas[0].components)

          this.isLoading = false
          this.$store.commit('project/setDataLengkapiMaterial', this.areas)
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message:
              'Gagal memuat data, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    handleValidateForm (payload) {
      this.validateForm = payload
      if (this.validateForm.length < 1) {
        this.$emit('isCompleteValidate', true)
      } else {
        this.$emit('isCompleteValidate', false)
      }
    },
    handleIsChange (payload) {
      this.$emit('isChange', true)
    },
    handleIsFillForm (index) {
      if (this.validateForm.find(e => e.area === index)) {
        return true
      } else {
        return false
      }
    }
  },
  created () {
    this.getDataLengkapiMaterial()
  }
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}

.h-100 {
  min-height: 85vh;
}

.white-space-normal {
  white-space: normal;
}

.btn-tabs {
  height: auto;
  border-radius: 100px;
  color: #9a9a9a;
  border: 1px solid #e1e1e1;
}

.btn-tabs.is-active {
  background: rgba(49, 133, 252, 0.1);
  color: #3185fc;
  border: 1px solid #3185fc;
}

.border-right {
  border-right: 1px solid #e1e1e1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.area-wrapper {
  display: flex;
  flex-direction: column;
}

.select-area-wrapper {
  margin-top: 15px;
  padding: 0 15px;
}

.error-wrapper {
  display: inline-flex;
  margin-top: -1rem;
  margin-bottom: 1.5rem;
}

.alert-icon-wrapper {
  position: absolute;
  right: 10px;
  top: 10px;
}

@media only screen and (min-width: 768px) {
  .area-wrapper {
    max-height: 65vh;
    overflow: auto;
  }
}

@media only screen and (max-width: 768px) {
  .area-wrapper {
    white-space: nowrap;
    overflow-x: scroll !important;
  }

  .area-wrapper {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
  }

  .area-wrapper::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  .white-space-md-nowrap {
    white-space: nowrap !important;
  }

  .border-right {
    border: none;
  }
}
@media only screen and (max-width: 600px) {
  .px-sm-3 {
    padding: 0 20px;
  }

  .w-sm-100 {
    width: 100%
  }

  .alert-icon-wrapper {
    position: relative !important;
    top: 0;
    right: 0;
    margin-left: 0.5rem;
  }
}
</style>
