<template>
  <div>
    <div v-for="(itemArea, indexArea) in areas" :key="itemArea.area_id">

      <div class="tabs tabs-area-wrapper thin-scroll user-select-text" v-if="selectedAreaId === itemArea.area_id">
        <div class="overflow-x-auto overflow-y-hidden thin-scroll tabs-component-wrapper">
          <ul v-if="sliceComponent1.length > 0">
            <li v-for="(itemComponent, indexComponent) in sliceComponent1" :key="itemComponent.component_name" :class="{'is-active averta-bold': currentClick === itemComponent.component_id}">
              <a @click="handleClick(itemComponent.component_id)">
                <div class="is-flex is-align-items-center">
                  <span>{{ itemComponent.component_name }}</span>
                  <div
                    v-if="handleValidateForm(indexArea, indexComponent) && showError"
                    class="ml-2"
                  >
                    <AlertIcon />
                  </div>
                </div>
              </a>
            </li>
          </ul>
          <ul v-if="sliceComponent2.length > 0">
            <li v-for="(itemComponent, indexComponent) in sliceComponent2" :key="itemComponent.component_name" :class="{'is-active averta-bold': currentClick === itemComponent.component_id}">
              <a @click="handleClick(itemComponent.component_id)">
                <div class="is-flex is-align-items-center">
                  <span>{{ itemComponent.component_name }}</span>
                  <div
                    v-if="handleValidateForm(indexArea, indexComponent) && showError"
                    class="ml-2"
                  >
                    <AlertIcon />
                  </div>
                </div>
              </a>
            </li>
          </ul>
          <ul v-if="sliceComponent3.length > 0">
            <li v-for="(itemComponent, indexComponent) in sliceComponent3" :key="itemComponent.component_name" :class="{'is-active averta-bold': currentClick === itemComponent.component_id}">
              <a @click="handleClick(itemComponent.component_id)">
                <div class="is-flex is-align-items-center">
                  <span>{{ itemComponent.component_name }}</span>
                  <div
                    v-if="handleValidateForm(indexArea, indexComponent) && showError"
                    class="ml-2"
                  >
                    <AlertIcon />
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div v-for="(itemComponent, indexComponent) in itemArea.components" :key="itemComponent.component_id">
          <div v-if="currentClick === itemComponent.component_id && selectedAreaId === itemArea.area_id">
            <div v-for="(itemDetail, indexDetail) in itemComponent.detail_components" :key="itemDetail.id">
              <p class="averta-bold is-uppercase white-space-initial" :class="{'mt-3': indexDetail === 0, 'mt-5': indexDetail > 0}">· {{itemDetail.detail_component_name}}</p>
              <div class="is-flex mt-2 mb-5 note-wrapper p-4">
                <p class="mr-2 white-space-no-wrap">Catatan :</p>
                <p>{{itemDetail.note}}</p>
              </div>
              <div class="mb-4" v-for="(itemItem, indexItem) in itemDetail.item" :key="indexItem">
                <div class="p-5 item-job-wrapper">
                  <div class="item-job">
                    <div class="align-self-sm-start item-working-wrapper">
                      <p>Item Pekerjaan</p>
                      <p class="averta-bold mb-1 white-space-initial ellipsis-3">{{itemItem.note_item_working}}</p>
                      <div v-if="itemItem.pictures && itemItem.pictures.length > 0" class="is-flex">
                        <div v-for="(itemPicture, indexPicture) in itemItem.pictures" :key="indexPicture" @click="openModalPreviewImage(itemItem.pictures)" class="wrapper-preview-image cursor-pointer is-relative mr-3">
                          <img :src="itemPicture" alt="">
                        </div>
                      </div>
                    </div>
                    <div v-if="user.roles[0].name === 'Konsultan Survey' || user.roles[0].name === 'Site Manager'" class="is-flex flex-sm-column is-align-items-flex-start w-sm-100">
                      <div class="mr-4 mr-sm-0 box-wrapper p-3">
                        <p class="has-text-centered">Volume Area Pengerjaan</p>
                        <p class="averta-bold has-text-centered">{{itemItem.volume}} {{itemItem.unit_name}}</p>
                      </div>
                      <div class="mr-4 mr-sm-0 box-wrapper p-3">
                        <p class="has-text-centered">Maks. Hari Kerja</p>
                        <p class="averta-bold has-text-centered">{{itemItem.max}} Hari</p>
                      </div>
                      <div class="box-wrapper p-3">
                        <p class="has-text-centered">Jumlah Tukang</p>
                        <p class="averta-bold has-text-centered">{{itemItem.total_worker}} Tukang</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-for="(itemMaterial, indexMaterial) in itemItem.materials" :key="itemMaterial.id" class="p-5 gray-wrapper border-top-dotted">
                  <div class="columns is-justify-content-space-between">
                    <div class="column is-4 is-flex is-justify-content-space-between">
                      <div>
                        <p class="white-space-no-wrap">Nama Material</p>
                        <p class="averta-bold white-space-initial">{{itemMaterial.product_name}}</p>
                        <div class="is-flex is-align-items-center">
                          <p v-if="itemMaterial.is_custom" class="white-space-no-wrap color-red is-italic">Material Custom</p>
                          <p v-if="itemMaterial.is_custom && itemMaterial.is_new ? '|' : ''" class="mx-1">|</p>
                          <p v-if="itemMaterial.is_new" class="white-space-no-wrap color-blue-1 is-italic">Material Baru</p>
                        </div>
                      </div>
                      <div class="trash-icon is-align-self-flex-end is-clickable is-hidden-desktop is-hidden-tablet" @click="handleDeleteMaterial(indexComponent, indexDetail, indexItem, indexMaterial)">
                        <img :src="require('@/assets/img/icon-delete.png')" alt="">
                      </div>
                    </div>
                    <div class="column is-8 is-flex is-justify-content-space-between flex-sm-column is-align-items-center">
                      <div class="mx-2 input-material-wrapper c-w-sm-100 mx-sm-0 mb-sm-3">
                        <p>Jumlah <span class="white-space-nowrap">Dibutuhkan <span style="color: red">*</span></span></p>
                        <input v-model.number="itemMaterial.total_need" @input="handleChangeEmit" type="number" min="0" oninput="validity.valid||(value='');" class="input input-material mt-2 has-text-centered averta-bold">
                        <ErrorComponent v-if="!itemMaterial.total_need && showError" />
                      </div>
                      <div class="mx-2 input-material-wrapper c-w-sm-100 mx-sm-0 mb-sm-3">
                        <p>Jumlah <span class="white-space-nowrap">Dipenawaran <span style="color: red">*</span></span></p>
                        <input v-model.number="itemMaterial.total_offering" @input="handleChangeEmit" type="number" min="0" oninput="validity.valid||(value='');" class="input input-material mt-2 has-text-centered averta-bold">
                        <ErrorComponent v-if="!itemMaterial.total_offering && showError" />
                      </div>
                      <div class="mx-2 input-material-wrapper c-w-sm-100 mx-sm-0">
                        <p>UOM <span>(Satuan) <span style="color: red">*</span></span></p>
                        <section class="w-100 mt-2 border-input">
                          <b-field>
                            <b-autocomplete
                              v-model="itemMaterial.uom_name"
                              placeholder="UOM (Satuan)"
                              :data="filteredDataSatuan"
                              field="name"
                              @select="option => handleSelectUnit(option, indexComponent, indexDetail, indexItem, indexMaterial)"
                              @input="e => handleChangeInput(e, indexArea, indexComponent, indexDetail, indexItem, indexMaterial)"
                              @focus="resetSearchSatuan"
                              open-on-focus
                              clearable
                            ></b-autocomplete>
                          </b-field>
                        </section>
                        <ErrorComponent v-if="(!itemMaterial.uom_id || !itemMaterial.uom_name) && showError" :title="isValidInputSatuan(itemMaterial.uom_name)" />
                      </div>
                      <div class="trash-icon is-align-self-flex-end is-clickable is-hidden-mobile" @click="handleDeleteMaterial(indexComponent, indexDetail, indexItem, indexMaterial)">
                        <img :src="require('@/assets/img/icon-delete.png')" alt="">
                      </div>
                    </div>
                  </div>
                  <div class="hide-material-wrapper p-4">
                    <div class="is-flex">
                      <p class="averta-bold mr-1">Catatan</p>
                      <p class="has-text-grey-light">(Opsional)</p>
                    </div>
                    <textarea
                      rows="2"
                      class="mt-2 textarea style-textarea"
                      v-model="itemMaterial.note"
                      @input="handleChangeEmit"
                      placeholder="Isi disini"
                    ></textarea>
                  </div>
                  <!-- Hide Variant 04 Oktober -->
                  <!-- <div v-if="itemMaterial.variants[0].length > 0" class="spesifik-wrapper p-4 pb-5 mt-5">
                    <div class="spesifik-text-wrapper">
                      <p class="averta-bold pb-3">Kebutuhan Spesifik</p>
                    </div>
                    <div class="columns mt-3 is-flex-wrap-wrap">
                      <div class="column is-4 pt-0" v-for="(itemVariants, indexVariants) in itemMaterial.variants[0]" :key="'A'+indexArea+'B'+indexVariants">
                        <p>{{itemVariants.name}} <span style="color: red">*</span></p>
                        <input :value="itemVariants.value_variant" @input="(e) => handleChangeEmitVariant(e, indexArea, indexComponent, indexDetail, indexItem, indexMaterial, indexVariants)" :placeholder="itemVariants.name" type="text" class="mt-2 input input-variants averta-bold">
                        <ErrorComponent v-if="!itemVariants.value_variant && showError" />
                      </div>
                    </div>
                  </div> -->
                </div>
                <!-- <div :class="{'border-top-dotted pt-4': itemDetail.materials.length === 0}" class="px-4 pb-4 add-material-wrapper" > -->
                <div class="px-4 pb-4 add-material-wrapper" >
                  <p
                    @click="handleShowModal(indexComponent, indexDetail, indexItem)"
                    class="color-red averta-bold has-text-centered is-clickable"
                  >+ Tambah Material</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-modal width="716px" v-model="modalSelectMaterial" :on-cancel="setDefaultModalState" :can-cancel="false">
        <div class="modal-template">
          <div class="modal-body w-100">
            <header class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="averta-bold is-size-5">Tambah Material</p>
              <div class="is-clickable" @click="modalSelectMaterial = false">
                <b-icon icon="close"></b-icon>
              </div>
            </header>
            <div class="wrap-content mt-5 mb-3 is-relative">
              <div class="mx-5">
                <label class="is-size-6">Pilih Material</label>
                <b-input type="text" icon="magnify" class="mt-1" placeholder="Cari Material" @input="e => getListMaterials(e)"></b-input>

                <div class="request-material-wrapper" v-if="!dataUpdateItemPekerjaan.isUpdate">
                  <div>
                    <p class="averta-bold">Material Tidak Ditemukan?</p>
                    <p>Request material baru untuk penawaran</p>
                  </div>
                  <button class="request-btn color-red averta-bold is-clickable" @click="handleOpenModalMaterial">Request Material</button>
                </div>

                <div class="list-wrapper pt-3">
                  <p class="label-16 averta-bold mb-4">Daftar Material</p>
                  <div
                    v-for="(item, index) in materials"
                    :key="item.id"
                    class="mb-4"
                  >
                    <div class="is-flex is-justify-content-space-between is-align-items-center">
                      <div class="is-flex is-align-items-center">
                        <input class="input-checkbox cursor-pointer" type="checkbox" :checked="isChecked(item.id)" @change="handleCheckMaterials($event, index, item.id)">
                        <div class="img-wrapper mr-3 ml-3">
                          <img :src="item.picture || require('@/assets/img/empty-material.png')" :alt="item.name" />
                        </div>
                        <div class="is-flex is-align-items-center pr-4 c-w-100">
                          <p class="title-material averta-bold">{{item.name}}</p>
                          <p v-if="item.is_new" class="is-italic color-blue-1 ml-1 white-space-nowrap">Material Baru</p>
                        </div>
                      </div>
                    </div>
                    <div v-if="isChecked(item.id)" class="custom-wrapper p-3 mt-3">
                      <p class="averta-bold mb-2">Material ini custom?</p>
                      <div class="is-flex is-align-items-center">
                        <input class="input-checkbox cursor-pointer mr-2" type="checkbox" @change="handleCheckIsCustom($event, item.id)">
                        <p>Ya, custom</p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <footer class="py-4 is-flex is-justify-content-flex-end footer-shadow justify-content-sm-center">
              <b-button
                class="btn-disabled averta-bold px-5 is-flex is-justify-content-center mr-5 w-sm-90 mr-sm-0"
                :class="{'btn-active': checkboxMaterial.length > 0}"
                @click="addMaterials"
              >
                <span class="text-btn mr-3">Tambah</span>
                <ArrowRightIcon class="check-position" />
              </b-button>
            </footer>
          </div>
        </div>
      </b-modal>
    </div>

    <ModalPreviewImage :isActive="modalPreviewImageActive" :dataImage="imagePreviewCarousel" title="" @closeModal="closeModalPreviewImage()" />
    <ModalMaterials typeModal="add-material-offering" :surveyId="parseInt($route.params.surveyId)" :showModal="modalMaterial" @closeModal="($event) => handleCloseModalMaterial($event)" />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ArrowRightIcon, AlertIcon } from '@/components/icons'
import ErrorComponent from '@/components/ErrorComponent.vue'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'
import ModalMaterials from '../../purchasing/components/modal/ModalMaterials.vue'

export default {
  name: 'TabComponentThree',
  components: {
    ErrorComponent,
    ArrowRightIcon,
    AlertIcon,
    ModalPreviewImage,
    ModalMaterials
  },
  props: ['dataAreas', 'dataComponents', 'selectedAreaId', 'selectedComponentId', 'indexArea', 'showError'],
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user',
      dataUpdateItemPekerjaan: 'project/getDataUpdateItemPekerjaanBS'
    }),
    filteredDataSatuan () {
      return this.satuan.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.searchSatuan.toLowerCase()) >= 0
        )
      })
    }
  },
  data () {
    return {
      areas: this.dataAreas,
      currentComponents: [],
      initMaterials: [],
      materials: [],
      checkboxMaterial: [],
      selectedMaterials: null,
      satuan: [],
      searchSatuan: '',
      data_uom: '',
      validateForm: [],
      imagePreviewCarousel: [],
      modalSelectMaterial: false,
      modalPreviewImageActive: false,
      modalMaterial: false,
      currentClick: this.selectedComponentId,
      sliceComponent1: this.dataComponents.sliceComponent1 || [],
      sliceComponent2: this.dataComponents.sliceComponent2 || [],
      sliceComponent3: this.dataComponents.sliceComponent3 || []
    }
  },
  methods: {
    isChecked (id) {
      const findObj = this.checkboxMaterial.find(item => item.id === id)

      if (findObj) return true
      return false
    },
    isEmptyMaterials (obj) {
      let value = false
      for (var key in obj) {
        if (key !== 'picture' && key !== 'variants' && key !== 'note') {
          if (obj[key] === null || obj[key] === '' || (obj[key] && obj[key].length < 0)) {
            value = true
          }
        }
      }
      return value
    },
    isValidInputSatuan (keyword) {
      if (!keyword) {
        return 'Belum Diisi'
      }

      const findItem = this.satuan.find((val) => val.name === keyword)
      if (!findItem) {
        return 'Tidak valid'
      }
    },
    closeModalPreviewImage () {
      this.imagePreviewCarousel = null
      this.modalPreviewImageActive = false
    },
    openModalPreviewImage (data) {
      this.imagePreviewCarousel = data
      this.modalPreviewImageActive = true
    },
    handleShowModal (indexComponent, indexDetail, indexItem) {
      this.selectedMaterials = {
        indexComponent,
        indexDetail,
        indexItem
      }
      this.modalSelectMaterial = true
      this.getListMaterials('')
    },
    getDataUnitSatuan () {
      const payload = {
        url: this.url,
        type: 'uom'
      }
      this.$store
        .dispatch('project/getListUnit', payload)
        .then((response) => {
          this.satuan = response.data.data
        })
        .catch((error) => {
          console.log(error)
          this.$buefy.toast.open({
            message: 'Gagal memuat data Unit Satuan, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    getListMaterials (keyword) {
      const payload = {
        url: this.url,
        search: keyword || '',
        offering: true,
        survey_id: parseInt(this.$route.params.surveyId)
      }
      this.$store
        .dispatch('product/getListProducts', payload)
        .then((response) => {
          if (this.initMaterials.length < 1) {
            this.initMaterials = response.data.data
          }
          this.materials = response.data.data
        })
        .catch((error) => {
          console.log(error)
          this.$buefy.toast.open({
            message: 'Gagal memuat data Material, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    saveMaterial () {
      const { indexComponent, indexDetail, indexItem } = this.selectedMaterials
      const arrMaterials = this.areas[this.indexArea].components[indexComponent].detail_components[indexDetail].item[indexItem].materials

      for (let index = 0; index < this.checkboxMaterial.length; index++) {
        const obj = {
          product_id: this.checkboxMaterial[index].id,
          product_name: this.checkboxMaterial[index].name,
          picture: this.checkboxMaterial[index].picture,
          is_custom: this.checkboxMaterial[index].is_custom,
          is_new: this.checkboxMaterial[index].is_new,
          // form input
          total_need: 0,
          total_offering: 0,
          uom_id: null,
          uom_name: '',
          note: null
        }
        arrMaterials.push(obj)
      }
      this.areas[this.indexArea].components[indexComponent].detail_components[indexDetail].item[indexItem].materials = arrMaterials

      this.handleChangeEmit()
      this.setDefaultModalState()
    },
    addMaterials () {
      const checkIsNew = this.checkboxMaterial.find(obj => obj.is_new && obj.is_new === true)
      if (checkIsNew) {
        this.modalSelectMaterial = false
        this.$swal({
          imageUrl: require('@/assets/img/delete-area-component.png'),
          imageAlt: 'new material',
          width: '600px',
          padding: '10px 10px 30px 10px',
          title: '<p class="averta-bold label-22" style="margin-bottom: -10px;">Ada Material Baru!</p>',
          html: '<p class="averta-regular" style="width: 300px; margin: auto;">Kamu memasukan material baru yang belum tersimpan. Pastikan informasi telah benar dan sesuai</p>',
          showCancelButton: true,
          confirmButtonText: 'Simpan',
          cancelButtonText: 'Cek Kembali',
          allowEscapeKey: false,
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn-hapus-detail-component',
            cancelButton: 'btn-batal-detail-component'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.saveMaterial()
          } else {
            this.modalSelectMaterial = true
          }
        })
      } else {
        this.saveMaterial()
      }
    },
    setDefaultModalState () {
      this.checkboxMaterial = []
      this.selectedMaterials = null
      this.modalSelectMaterial = false
    },
    handleValidateForm (indexArea, indexComp) {
      if (
        this.validateForm.find(
          (e) => e.area === indexArea && e.component === indexComp
        )
      ) {
        return true
      } else {
        return false
      }
    },
    handleChangeEmit () {
      this.$emit('handleChangeStep', true)
    },
    handleCheckMaterials (e, index, id) {
      if (e.target.checked) {
        // push materials to checkboxMaterial
        const materials = this.materials[index]
        materials.is_custom = false
        this.checkboxMaterial.push(materials)
      } else {
        // remove when not checked
        const objWithIdIndex = this.checkboxMaterial.findIndex((obj) => obj.id === id)
        this.checkboxMaterial.splice(objWithIdIndex, 1)
      }
    },
    handleCheckIsCustom (e, id) {
      const index = this.checkboxMaterial.findIndex((obj) => obj.id === id)
      this.checkboxMaterial[index].is_custom = e.target.checked
    },
    handleDeleteMaterial (indexComponent, indexDetail, indexItem, indexMaterial) {
      this.areas[this.indexArea].components[indexComponent].detail_components[indexDetail].item[indexItem].materials.splice(indexMaterial, 1)
      this.handleChangeEmit()
    },
    resetSearchSatuan () {
      this.searchSatuan = ''
    },
    handleSelectUnit (option, indexComponent, indexDetail, indexItem, indexMaterial) {
      if (!option) {
        return
      }
      this.areas[this.indexArea].components[indexComponent].detail_components[
        indexDetail
      ].item[indexItem].materials[indexMaterial].uom_name = option.name
      this.areas[this.indexArea].components[indexComponent].detail_components[
        indexDetail
      ].item[indexItem].materials[indexMaterial].uom_id = option.id
      this.handleChangeEmit()
    },
    handleChangeInput (e, indexArea, indexComponent, indexDetail, indexItem, indexMaterial) {
      this.searchSatuan = e
      const findItem = this.satuan.find((val) => val.name === e)
      if (!findItem) {
        this.areas[indexArea].components[indexComponent].detail_components[indexDetail].item[indexItem].materials[indexMaterial].uom_id = null
      }
    },
    handleIsChange () {
      this.$emit('handleIsChange', true)
    },
    handleOpenModalMaterial () {
      this.modalSelectMaterial = false
      this.modalMaterial = true
    },
    handleCloseModalMaterial (event) {
      if (event === 'success-add-material-offering') {
        this.getListMaterials('')
      }
      this.modalMaterial = false
      this.modalSelectMaterial = true
    },
    handleClick (value) {
      this.currentClick = value
    },
    async validateAreas (arr) {
      if (!arr || arr.length < 1) {
        return
      }
      await arr.map((itemArea, indexArea) => {
        itemArea.components.map((itemComp, indexComp) => {
          itemComp.detail_components.map((itemDetail, indexDetail) => {
            itemDetail.item.map((itemItem, indexItem) => {
              const newObj = {
                area: indexArea,
                component: indexComp,
                detail: indexDetail,
                item: indexItem
              }
              const found = this.validateForm.some(
                (o) =>
                  o.area === indexArea &&
                  o.component === indexComp &&
                  o.detail === indexDetail &&
                  o.item === indexItem
              )
              const idx = this.validateForm.findIndex(
                (x) =>
                  x.area === indexArea &&
                  x.component === indexComp &&
                  x.detail === indexDetail &&
                  x.item === indexItem
              )
              if (itemItem.materials && itemItem.materials.length > 0) {
                itemItem.materials.map((itemMaterials) => {
                  const isEmptyMaterials = this.isEmptyMaterials(itemMaterials)
                  if (isEmptyMaterials) {
                    if (found) {
                      this.validateForm[idx] = newObj
                    } else {
                      this.validateForm.push(newObj)
                    }
                  } else {
                    if (found) {
                      this.validateForm.splice(idx, 1)
                    }
                  }
                })
              } else {
                if (found) {
                  this.validateForm[idx] = newObj
                } else {
                  this.validateForm.push(newObj)
                }
              }
            })
          })
        })
      })
      this.$emit('handleValidateForm', this.validateForm)
      this.$emit('handleChangeStep', true)
    }
  },
  created () {
    this.getDataUnitSatuan()
    this.getListMaterials()
    this.validateAreas(this.areas)
  },
  watch: {
    areas: {
      handler: function (newVal) {
        if (newVal || newVal > 0) {
          if (this.user.roles[0].name === 'Site Manager') {
            this.handleIsChange()
          }
          this.validateAreas(newVal)
        }
      },
      deep: true
    },
    dataComponents (newVal) {
      this.sliceComponent1 = newVal.sliceComponent1 || []
      this.sliceComponent2 = newVal.sliceComponent2 || []
      this.sliceComponent3 = newVal.sliceComponent3 || []
    },
    indexArea () {
      // use IndexArea because cant detect selectedComponentId with selectedComponentId watch
      this.currentClick = this.selectedComponentId
    }
  }
}
</script>

<style scoped>
.white-space-no-wrap {
  white-space: nowrap;
}

.note-wrapper {
  background: #fafafa;
  border-radius: 100px;
}

.item-job-wrapper {
  background: #fafafa;
  border-radius: 12px 12px 0 0;
}

.item-job {
  display: flex;
  justify-content: space-between;
}

.border-top-dotted {
  border-top: 1px dashed #e1e1e1;
}

.gray-wrapper {
  background: #fafafa;
}

.add-material-wrapper {
  background: #fafafa;
  border-radius: 0 0 12px 12px;
}

.input-variants {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
}

.input-text {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  min-width: 15em;
}

.input-material {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modal-template {
  margin: auto;
}

.modal-body {
  overflow: hidden;
  background: white;
  border-radius: 10px;
}

.wrap-content {
  /* height: 60vh; */
  overflow-y: auto;
}

.modal-template header {
  background-color: #911a1e;
  height: 5em;
  color: white;
  padding: 0 20px;
}

.img-wrapper {
  width: 59px;
  height: 59px;
  box-shadow: 6px 7px 50px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.img-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.btn-active {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.25%);
  border-radius: 100px;
  color: white;
}

.btn-disabled {
  background-color: #9a9a9a;
  border-radius: 100px;
  color: white;
}

.footer-shadow {
  position: relative;
  width: 100%;
  background-color: white;
  box-shadow: 0px -3px 30px 0px #00000012;
}

.check-position {
  position: relative;
  top: 3px;
  fill: white;
}

.text-btn {
  position: relative;
  bottom: 1px;
}

.box-wrapper {
  background-color: #fff;
  border-radius: 12px;
}

.hide-material-wrapper {
  background: #FFFFFF;
  border: 1px solid #E1E1E1;
  border-radius: 12px;
}

.spesifik-wrapper {
  background: #FFFFFF;
  border-radius: 20px;
}

.spesifik-text-wrapper {
  border-bottom: 1px dashed #E1E1E1;
}

.trash-icon svg {
  width: 25px;
  height: 25px;
}

.custom-wrapper {
  background-color: #fafafa;
  border-radius: 12px;
}

.input-checkbox {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.list-wrapper {
  height: 20em;
  overflow-y: scroll;
  overflow-x: hidden;
  border-top: 1px dashed #E1E1E1;
}

.style-textarea {
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  height: auto;
}

.style-textarea:focus {
  outline: none;
}
.input-material-wrapper {
  white-space: nowrap;
  width: 30%;
}

.input-material-wrapper p {
  white-space: break-spaces;
}

.request-material-wrapper {
  background: linear-gradient(90deg, #DAFFF8 0%, #CBFFD4 100%);
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  margin-top: 1em;
  margin-bottom: 1em;
}

.request-btn {
  border-radius: 100px;
  outline: none;
  border: 2px solid #d9272d;
  padding: 10px 12px;
  background: transparent;
}

.title-material {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-working-wrapper {
  width: 25em;
}

@media only screen and (max-width: 600px) {
  .align-self-sm-start {
    align-self: flex-start
  }

  .flex-sm-column {
    flex-direction: column;
  }

  .item-job {
    width: 100%;
    flex-direction: column;
  }

  .w-sm-100 {
    width: 100%;
  }

  .w-sm-90 {
    width: 90%;
  }

  .mr-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .box-wrapper {
    margin-top: 1.5rem;
    width: 100%;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap;
  }

  .flex-sm-1 {
    width: auto;
    flex: 1
  }

  .list-wrapper {
    height: 15em !important;
  }

  .img-wrapper {
    width: 45px !important;
    height: 45px !important;
  }

  .item-working-wrapper {
    width: 100%;
  }
}

</style>
